import DropPanel from './components/DropPanel';
import MainMenu  from './components/MainMenu';
import accord    from './components/accord';
import cursorS   from './components/cursor';
import menu      from './components/MainMenu';
import modal     from './components/modal';

// Init
function init() {
	accord();
	cursorS();
	menu();
	modal;

	function jaw() {
		const jaw      = document?.querySelectorAll('.jaw__item');
		const jawInput = document?.getElementById('jaw-input');
		const jaws     = [];
		jaw.forEach((e) => {
			e.addEventListener('click', function () {
				e.classList.toggle('active');
				const indx = jaws.indexOf(this.dataset.value);
				if (~indx) {
					jaws.splice(indx, 1);
				} else {
					jaws.push(this.dataset.value);
				}
				jawInput.value = jaws;
				if (jawInput.value === '') {
					jawInput.classList.remove('active');
				} else {
					jawInput.classList.add('active');
				}
			});
		});
	}

	jaw();

	function steps() {
		const stepBtn = document?.querySelectorAll('.btn-step');
		const counter = 0;
		stepBtn.forEach((item) => {
			item.addEventListener('click', (e) => {
				if (e.target.classList.contains('btn-step--prev')) {
					document.querySelector('.form__step--one').classList.add('active');
					document.querySelector('.form__step--two').classList.remove('active');
					document.querySelector('.counter').innerHTML = 1;
				}
				if (e.target.classList.contains('btn-step--next')) {
					document.querySelector('.form__step--one').classList.remove('active');
					document.querySelector('.form__step--two').classList.add('active');
					document.querySelector('.counter').innerHTML = counter + 2;
				}
			});
		});
	}

	steps();

	function checked() {
		const checkbox = document.getElementById('checkbox');
		const patient  = document.querySelector('.patient');
		const doctor   = document.querySelector('.doctor');

		checkbox.addEventListener('click', () => {
			if (checkbox.checked) {
				doctor.classList.add('active');
				patient.classList.remove('active');
			} else {
				patient.classList.add('active');
				doctor.classList.remove('active');
			}
		});
	}

	checked();

	const appHeight = () => {

		const doc = document.documentElement;
		doc.style.setProperty('--app-height', `${window.innerHeight}px`);
	};
	window.addEventListener('resize', appHeight);
	appHeight();
}

(function () {
	init();
}());
