const accord = () => {
	const tabsBtn   = document.querySelectorAll('.tabs-btn');
	const tabsItems = document.querySelectorAll('.tabs-item');

	tabsBtn.forEach((item) => {
		item.addEventListener('click', () => {
			const currentBtn = item;
			const tabId      = currentBtn.getAttribute('data-tab');
			const currentTab = document.querySelector(tabId);

			if (!currentBtn.classList.contains('active')) {
				tabsBtn.forEach((item) => {
					item.classList.remove('active');
				});

				currentBtn.classList.add('active');

				tabsItems.forEach((item) => {
					item.classList.remove('active');
				});
				currentTab.classList.add('active');
			}
		});
	});
};

export default accord;
