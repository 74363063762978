import { gsap }                from 'gsap';
import { TweenMax, TweenLite } from 'gsap/gsap-core';

gsap.registerPlugin(TweenMax);

const cursorS = () => {
	// const cursor     = document.querySelector('.cursor');
	// const follower   = document.querySelector('.follower');
	// const hoverTitle = document.querySelectorAll('.hover-title');
	// const hoverBtn   = document.querySelectorAll('.btn-wrapper');
	// const swiperBtn  = document.querySelectorAll('.swiper-nav__button');
	//
	// let posX = 0;
	// let posY = 0;
	//
	//
	//
	// TweenMax.to({}, 0.01, {
	// 	repeat   : -1,
	// 	onRepeat : () => {
	// 		posX += (mouseX - posX) / 5;
	// 		posY += (mouseY - posY) / 5;
	//
	// 		TweenMax.set(follower, {
	// 			css : {
	// 				left : posX - 12,
	// 				top  : posY - 12,
	// 			},
	// 		});
	// 		TweenMax.set(cursor, {
	// 			css : {
	// 				left : mouseX,
	// 				top  : mouseY,
	// 			},
	// 		});
	// 	},
	// });

	let mouseX = 0;
	let mouseY = 0;

	window.addEventListener('mousemove', (e) => {
		mouseX             = e.clientX;
		mouseY             = e.clientY;
		const contacts     = document.querySelector('.contacts');
		const parallaxItem = document.querySelector('.contacts__hand');

		const pageX  = event.pageX - (contacts.getBoundingClientRect().width * 0.5);
		const fixer  = 0.0030;
		const speedX = parallaxItem.getAttribute('data-speed-x');
		TweenLite.set(parallaxItem, {
			x : (parallaxItem.offsetLeft + pageX * speedX) * fixer,
		});
	});

	// hoverTitle.forEach((item) => {
	// 	item.addEventListener('mouseenter', () => {
	// 		cursor.classList.add('active');
	// 		follower.classList.add('active');
	// 	});
	//
	// 	item.addEventListener('mouseleave', () => {
	// 		cursor.classList.remove('active');
	// 		follower.classList.remove('active');
	// 	});
	// });
	//
	// hoverBtn.forEach((item) => {
	// 	item.addEventListener('mouseenter', () => {
	// 		cursor.classList.add('hover');
	// 		follower.classList.add('hover');
	// 	});
	//
	// 	item.addEventListener('mouseleave', () => {
	// 		cursor.classList.remove('hover');
	// 		follower.classList.remove('hover');
	// 	});
	// });
	//
	// swiperBtn.forEach((item) => {
	// 	item.addEventListener('mouseenter', () => {
	// 		cursor.classList.add('hover');
	// 		follower.classList.add('hover--small');
	// 	});
	//
	// 	item.addEventListener('mouseleave', () => {
	// 		cursor.classList.remove('hover');
	// 		follower.classList.remove('hover--small');
	// 	});
	// });
};

export default cursorS;
