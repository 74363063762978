import GraphModal from 'graph-modal';

const modal = new GraphModal({

	isOpen : (modal) => {
		window.fullpage_api.setAllowScrolling(false);
	},
	isClose : () => {
		window.fullpage_api.setAllowScrolling(true);
	},
});

export default modal;
