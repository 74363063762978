const menu = () => {
	const burgerEl = document?.querySelector('[data-burger]');
	const menu     = document?.querySelector('[data-menu]');
	const navLink  = document?.querySelectorAll('.nav__link');

	burgerEl?.addEventListener('click', () => {
		burgerEl?.classList.toggle('burger--active');
		menu?.classList.toggle('active');

		if (menu?.classList.contains('active')) {
			burgerEl?.setAttribute('aria-expanded', 'true');
			burgerEl?.setAttribute('aria-label', 'Сlose menu');
			window.fullpage_api.setAllowScrolling(false);
		} else {
			burgerEl?.setAttribute('aria-expanded', 'false');
			burgerEl?.setAttribute('aria-label', 'Open menu');
			window.fullpage_api.setAllowScrolling(true);
		}
	});

	navLink?.forEach((item) => {
		item.addEventListener('click', () => {
			menu.classList.remove('active');
			burgerEl.classList.remove('burger--active');
			window.fullpage_api.setAllowScrolling(true);
		});
	});

};

export default menu;
